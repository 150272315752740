body {
  margin: 0;
  font-family: -apple-system, Helvetica Neue, Helvetica, BlinkMacSystemFont,
    "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.extra-bold {
  font-weight: 800;
}

h2 {
  font-size: 2.8rem !important;
}

.top-padded {
  padding-top: 40px;
}

.bottom-padded {
  padding-bottom: 20px;
}

.bottom-xl-padded {
  padding-bottom: 30px;
}

.flex-center {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
}

.form-wrapper {
  width: 100%;
}

a {
  color: #4969ed;
  font-weight: 600;
}

h1,
h2 {
  letter-spacing: -2px !important;
  font-weight: 800;
}

@media (min-width: 992px) {
  .form-wrapper {
    width: 40%;
  }

  .bottom-xl-padded {
    padding-bottom: 60px;
  }
}
