.brand-name {
  font-size: 30px;
  margin-left: 10px;
}

.navbar-brand {
  display: flex !important;
  align-items: center;
}

.nav-link {
  margin-right: 10px;
}
